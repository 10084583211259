@import 'variables';
  

@mixin allStyles($styles) {
  @each $property, $value in $styles {
    #{$property}: $value;
  }
}

@mixin actionButton {
  border: 0;
  outline: none;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity .2s;
  opacity: .9;
  &:not(&:disabled) {
    transition: background-color .2s, color .2s, opacity .2s;
    &:hover {
      opacity: 1;
    }
  }
}

@mixin buttonsContainer {
  & button,
  & a {
    @include actionButton;
    color: $white;
    min-width: 195px;
    width: 100%;
  }
}

@mixin orangeButton {
  font-size: .75em;
  background-color: $orange;
  opacity: .9;
}

@mixin greenButton {
  background-color: $greenAlt;
  font-size: .75em;
}

@mixin flexRow($styles: ()) {
  @include allStyles($styles);
  display: flex;
  flex-direction: row;
}

/** @todo переделать на использование flexRow */
@mixin flexColumn($styles: ()) {
  @include allStyles($styles);
  display: flex;
  flex-direction: column;
}

@mixin under($width, $styles: ()) {
  @media screen and (max-width: $width) {
    @include allStyles($styles);
  }
}

@mixin above($width, $styles: ()) {
  @media screen and (min-width: $width) {
    @include allStyles($styles);
  }
}

@mixin inBounds($from, $to, $styles: ()) {
  @media screen and (min-width: $from) and (max-width: $to) {
    @include allStyles($styles);
  }
}

@mixin desktop($styles) {
  @include above($desktopMin, $styles);
}

@mixin tablet($styles) {
  @include inBounds($tabletMin, $tabletMax, $styles);
}

@mixin phoneHD($styles) {
  @include inBounds($phoneHdMin, $phoneHdMax, $styles);
}

@mixin phone($styles) {
  @include inBounds($phoneMin, $phoneMax, $styles);
}

@mixin landscape($styles) {
  @media screen and (max-height: 660px) and (orientation: landscape) {
    @include allStyles($styles);
  }
}
