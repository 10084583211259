@import 'variables';


body {
  margin: 0;
  & * {
    font-family: $montserrat;
  }
}

.hidden {
  visibility: hidden;
}

.pointerCursor {
  cursor: pointer;
}