// цвета
$white: #ffffff;
$dirtyWhite: hsl(0, 0%, 85%);
$black: #2b2b2b;
$lightBlack: rgba(97, 97, 97, .85);
$grey: #6a6a6a;
$greyAlt: #808080;
$greyAlt2: #f1f1f1;
$greyAlt3: #dddddd;
$greyAlt4: #d7d7d7;
$selectionArea: rgba(20, 10, 142, .2);
$red: #ff0000;
$yellow: yellow;
$green: #82C1A8;
$greenAlt: #14ab78;
$greenAltDark: darken($greenAlt, 20%);
$pink: pink;
$blue: #03a9f4;
$orange: #EF7E36;
$opaque: rgba(0, 0, 0, 0);
$opaqueBlack: hsl(0, 0%, 0%, .3);
// шрифты
$font5XL: 2.5rem;
$font2XL: 1.75rem;
$fontXL: 1.5rem;
$fontL: 1.25rem;
$fontM: 1rem;
$fontS: .75rem;
$fontXS: .5rem;
$font2XS: .25rem;
$montserrat: Montserrat;
// размеры
$desktopHd: 1920px;
$desktopMax: 1919px;
$desktopMin: 1280px;
$tabletMax: 1279px;
$tabletMin: 800px;
$phoneHdMax: 799px;
$phoneHdMin: 600px;
$phoneMax: 599px;
$phoneMin: 320px;