/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: normal;
    src: url("Montserrat-Thin.woff2") format("woff2"),
         url("Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: italic;
    src: url("Montserrat-ThinItalic.woff2") format("woff2"),
         url("Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    src: url("Montserrat-ExtraLight.woff2") format("woff2"),
         url("Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    src: url("Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("Montserrat-Light.woff2") format("woff2"),
         url("Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("Montserrat-LightItalic.woff2") format("woff2"),
         url("Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("Montserrat-Regular.woff2") format("woff2"),
         url("Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("Montserrat-Italic.woff2") format("woff2"),
         url("Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("Montserrat-Medium.woff2") format("woff2"),
         url("Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("Montserrat-MediumItalic.woff2") format("woff2"),
         url("Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("Montserrat-SemiBold.woff2") format("woff2"),
         url("Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url("Montserrat-SemiBoldItalic.woff2") format("woff2"),
         url("Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("Montserrat-Bold.woff2") format("woff2"),
         url("Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("Montserrat-BoldItalic.woff2") format("woff2"),
         url("Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("Montserrat-ExtraBold.woff2") format("woff2"),
         url("Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("Montserrat-Black.woff2") format("woff2"),
         url("Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("Montserrat-BlackItalic.woff2") format("woff2"),
         url("Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: normal;
    src: url("MontserratAlternates-Thin.woff2") format("woff2"),
         url("MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: italic;
    src: url("MontserratAlternates-ThinItalic.woff2") format("woff2"),
         url("MontserratAlternates-ThinItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: normal;
    src: url("MontserratAlternates-ExtraLight.woff2") format("woff2"),
         url("MontserratAlternates-ExtraLight.woff") format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: italic;
    src: url("MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
         url("MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: normal;
    src: url("MontserratAlternates-Light.woff2") format("woff2"),
         url("MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: italic;
    src: url("MontserratAlternates-LightItalic.woff2") format("woff2"),
         url("MontserratAlternates-LightItalic.woff") format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: normal;
    src: url("MontserratAlternates-Regular.woff2") format("woff2"),
         url("MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: italic;
    src: url("MontserratAlternates-Italic.woff2") format("woff2"),
         url("MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: normal;
    src: url("MontserratAlternates-Medium.woff2") format("woff2"),
         url("MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: italic;
    src: url("MontserratAlternates-MediumItalic.woff2") format("woff2"),
         url("MontserratAlternates-MediumItalic.woff") format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: normal;
    src: url("MontserratAlternates-SemiBold.woff2") format("woff2"),
         url("MontserratAlternates-SemiBold.woff") format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: italic;
    src: url("MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
         url("MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: normal;
    src: url("MontserratAlternates-Bold.woff2") format("woff2"),
         url("MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: italic;
    src: url("MontserratAlternates-BoldItalic.woff2") format("woff2"),
         url("MontserratAlternates-BoldItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: normal;
    src: url("MontserratAlternates-ExtraBold.woff2") format("woff2"),
         url("MontserratAlternates-ExtraBold.woff") format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: italic;
    src: url("MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
         url("MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 900;
    font-style: normal;
    src: url("MontserratAlternates-Black.woff2") format("woff2"),
         url("MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("MontserratAlternates-BlackItalic.woff2") format("woff2"),
         url("MontserratAlternates-BlackItalic.woff") format("woff");
}